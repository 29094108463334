<template>
  <div class="inside-page-content">
    <div class="paper light-grey-bg">
      <div class="flex-box">
        <vertical-menu
          class="left-menu-box"
          title="院士(专家)小镇"
          :menuData="menuData"
          @handleMenuChange="handleMenuChange"
          :defaultCode="menu_id"
        ></vertical-menu>
        <!-- 小镇展示 -->
        <div class="right-list-box" v-if="menu_id=='xiaozhenzhanshi'">
          <div class="xiaozhenzhanshi h4">
            <div class="item-wrapper" v-if="dataList1[0]">
              <div class="content" v-html="dataList1[0]['introduction']"></div>
              <div class="blue-tip"></div>
              <img :src="dataList1[0]['pictureUrl']" class="image" />
            </div>
            <div class="item-wrapper" v-if="dataList1[1]">
              <img :src="dataList1[1]['pictureUrl']" class="image" style="left: 15px;z-index: 1;" />
              <div class="blue-tip"></div>
              <div class="content" style="left: -15px;z-index: 3;">
                <div v-html="dataList1[1]['introduction']" class="ql-editor"></div>
              </div>
            </div>
            <div class="item-wrapper" v-if="dataList1[2]">
              <div class="content" v-html="dataList1[2]['introduction']"></div>
              <div class="blue-tip"></div>
              <img :src="dataList1[2]['pictureUrl']" class="image" />
            </div>
          </div>
        </div>
        <!-- 院士专家介绍 -->
        <div class="right-list-box" v-if="menu_id=='yuanshijieshao'">
          <cell-yuanshijieshao
            v-for="item in dataList2"
            :key="item.id"
            @handleClick="yuanshiDetail"
            :info="item"
          ></cell-yuanshijieshao>
          <div class="footer">
            <div class="left">共 {{total2}} 条</div>
            <el-pagination
              background
              layout="prev, pager, next"
              :total="total2"
              :current-page.sync="queryParams2.pageNum"
              :page-size="queryParams2.pageSize"
              @current-change="getList2"
            ></el-pagination>
          </div>
        </div>
        <!-- 院士大讲堂 -->
        <div class="right-list-box" v-if="menu_id=='yuanshijiangtang'" style="padding: 20px;">
          <top-filter
            :config="{'technical':true}"
            :sortable="{new: true, hot: true}"
            :hasSearch="true"
            @handleSearch="getList3ByFilter"
            @handleSearchParams="getList3ByFilter"
          ></top-filter>
          <div style="width: 100%;height: 1px;background-color: #E6E6E6;margin-top: 30px;"></div>
          <div class="activity-grid-wrapper">
            <div
              v-for="item in dataList3"
              :key="item.id"
              class="item-wrapper"
              @click="jiangtangDetail(item)"
            >
              <img :src="picInItem(item)" class="image" />
              <div class="title h2">{{ item.title }}</div>
              <div class="footer-wrapper">
                <div
                  class="time h4"
                  style="color: #666;"
                >[时间]：{{ item.forumTime?item.forumTime.substring(0,10):'--' }}</div>
              </div>
            </div>
            <!-- 补位 -->
            <div v-for="item in jigouBuwei" :key="item" class="item-wrapper" style="border: none;"></div>
          </div>
          <div class="footer">
            <div class="left">共 {{total3}} 条</div>
            <el-pagination
              background
              layout="prev, pager, next"
              :total="total3"
              :current-page.sync="queryParams3.pageNum"
              :page-size="queryParams3.pageSize"
              @current-change="getList3"
            ></el-pagination>
          </div>
        </div>
        <!-- 政策介绍 -->
        <div class="right-list-box" v-if="menu_id=='zhengcezhichi'">
          <article-list @handleClick="articleClick" :data="dataList4"></article-list>
          <div class="footer">
            <div class="left">共 {{total4}} 条</div>
            <el-pagination
              background
              layout="prev, pager, next"
              :total="total4"
              :current-page.sync="queryParams4.pageNum"
              :page-size="queryParams4.pageSize"
              @current-change="getList4"
            ></el-pagination>
          </div>
        </div>
        <!-- 入驻申请 -->
        <div class="right-list-box" v-if="menu_id=='ruzhushenqing'">
          <div style="width: 80%;margin: 20px auto;">
            <div
              style="height: 44px;background-color: #E8EDF8;text-align: center;line-height: 44px;margin-bottom: 20px;"
              class="h2"
            >院士(专家)入驻申请</div>
            <el-form ref="form" :model="form" label-width="120px" label-suffix=":">
              <el-form-item label="您的领域">
                <el-input v-model="form.field" placeholder="请输入您的领域"></el-input>
              </el-form-item>

              <el-form-item label="您的简介">
                <el-input
                  type="textarea"
                  v-model="form.introduction"
                  placeholder="请输入您的简介"
                  rows="4"
                  resize="none"
                ></el-input>
              </el-form-item>
              <el-form-item label="姓名" required>
                <el-input v-model="form.contactName" placeholder="请输入您的姓名"></el-input>
              </el-form-item>
              <el-form-item label="您的联系方式" required>
                <el-input v-model="form.contactPhone" placeholder="请输入您的电话号码"></el-input>
              </el-form-item>
              <div style="display: flex;justify-content: center;">
                <el-button @click="goback" style="margin-right: 50px;width: 150px;">取消</el-button>
                <el-button type="primary" @click="onSubmit" style="width: 150px;">提交</el-button>
              </div>
            </el-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import articleList from '@/components/articleList.vue'
import verticalMenu from '@/components/verticalMenu.vue'
import cellYuanshijieshao from './components/cell-yuanshijieshao.vue'
import topFilter from '@/components/topFilter.vue'

export default {
  name: 'Home',
  components: {
    articleList,
    verticalMenu,
    cellYuanshijieshao,
    topFilter
  },
  data() {
    return {
      menuData: [{
        'name': '小镇展示',
        'code': 'xiaozhenzhanshi'
      },
      {
        'name': '院士（专家）介绍',
        'code': 'yuanshijieshao'
      },
      {
        'name': '院士大讲堂',
        'code': 'yuanshijiangtang'
      },
      {
        'name': '政策支持',
        'code': 'zhengcezhichi'
      },
      {
        'name': '入驻申请',
        'code': 'ruzhushenqing'
      }
      ],
      menu_id: 'xiaozhenzhanshi',
      form: {},
      formLoading: false,
      dataList1: [],
      queryParams1: {
        pageNum: 1,
        pageSize: 6,
      },
      total1: 0,
      dataList2: [],
      queryParams2: {
        pageNum: 1,
        pageSize: 6,
      },
      total2: 0,
      dataList3: [],
      queryParams3: {
        pageNum: 1,
        pageSize: 6,
      },
      total3: 0,
      dataList4: [],
      queryParams4: {
        pageNum: 1,
        pageSize: 6,
      },
      total4: 0,
    }
  },
  computed: {
    jigouBuwei() {
      const v = this.dataList3.length % 3
      if (v != 0) {
        return 3 - v
      }
      return 0
    },
  },
  created(options) {
    console.log('options = ', options)
    console.log('this.$route.query = ', this.$route.query)
    const menu_id = this.$route.query?.menu_id
    if (menu_id && menu_id != 'undefined') {
      this.menu_id = menu_id
    }
  },
  mounted() {
    this.getList1()
    this.getList2()
    this.getList3()
    this.getList4()
  },
  methods: {
    picInItem(v) {
      if (v?.itemPicUrl) {
        return v.itemPicUrl
      }
      if (v?.pictureUrl) {
        return v.pictureUrl
      }
    },
    getList1() {
      const params = {
        ...this.queryParams1,
      }
      this.$api.solicitationCloud.expertTownList(params).then((res) => {
        //console.log('res = ', res)
        const data = res.data
        if (data) {
          this.dataList1 = data.rows;
          this.total1 = data.total;
        }
      })
    },
    getList2() {
      const params = {
        ...this.queryParams2,
      }
      this.$api.solicitationCloud.expertList(params).then((res) => {
        //console.log('res = ', res)
        const data = res.data
        if (data) {
          this.dataList2 = data.rows;
          this.total2 = data.total;
        }
      })
    },
    getList3ByFilter(v) {
      //TODO
      this.queryParams3['field'] = v?.technical
      this.queryParams3['keywords'] = v?.keyword
      this.queryParams3['sort'] = v?.sort
      this.getList3()
    },
    getList3() {
      const params = {
        ...this.queryParams3,
      }
      this.$api.solicitationCloud.expertForumList(params).then((res) => {
        //console.log('res = ', res)
        const data = res.data
        if (data) {
          this.dataList3 = data.rows;
          this.total3 = data.total;
        }
      })
    },
    getList4() {
      const params = {
        ...this.queryParams4,
      }
      this.$api.solicitationCloud.expertPolicyList(params).then((res) => {
        //console.log('res = ', res)
        const data = res.data
        if (data) {
          this.dataList4 = data.rows;
          this.total4 = data.total;
        }
      })
    },
    handleMenuChange(item) {
      console.log('handleMenuChange = ', item)
      this.menu_id = item.code
    },
    articleClick(item) {
      this.$router.push({ path: '/solicitationCloud/page-ysxz/articledetail', query: { id: item.id, 'detailType': 'soli-ysxzpolicy' } })
    },
    yuanshiDetail(item) {
      this.$router.push('/solicitationCloud/page-ysxz/detail-yuanshizhuanjia?id=' + item.id)
    },
    jiangtangDetail(item) {
      this.$router.push('/solicitationCloud/page-ysxz/detail-yuanshijiangtang?id=' + item.id)
    },
    goback() {
      this.$router.back()
    },
    onSubmit() {
      if (!this.form.contactName) {
        this.$message.error('请输入您的姓名');
        return
      }
      if (!this.form.contactPhone) {
        this.$message.error('请输入您的联系方式');
        return
      }
      this.formLoading = true
      this.$api.solicitationCloud.expertApply(this.form).then((res) => {
        this.formLoading = false
        const code = res.data.code
        if (code == 200) {
          this.$message({
            message: '提交成功',
            type: 'success'
          });
        }
      }).catch(() => {
        this.formLoading = false
      })
    },
  }
}
</script>
<style scoped lang="less">
.xiaozhenzhanshi {
  padding: 20px;

  .item-wrapper {
    width: 100%;
    min-height: 220px;
    padding-bottom: 20px;
    border-bottom: #dfdfdf 1px solid;
    position: relative;
    display: flex;
    margin-bottom: 20px;
    justify-content: space-between;

    .content {
      width: 40%;
      min-height: 150px;
      padding: 15px;
      overflow: hidden;
      text-overflow: ellipsis;
      box-shadow: 0px 0px 10px #dfdfdf;
      background-color: #fff;
      z-index: 3;
      position: relative;
      left: 15px;
      top: 5px;
      flex: 1;
      min-width: 100px;

      .title {
        font-weight: 600;
        margin-bottom: 10px;
      }
    }

    .blue-tip {
      width: 30px;
      height: 150px;
      background-color: #2d7acd;
      z-index: 2;
    }

    .image {
      width: 40%;
      height: 240px;
      z-index: 1;
      position: relative;
      left: -15px;
      top: 15px;
      flex: 1;
      min-width: 100px;
    }
  }
}
.activity-grid-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 20px;

  .item-wrapper {
    display: flex;
    flex-direction: column;
    width: 30%;
    margin: 20px 0;
    border: solid 1px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    .image {
      // width: 100%;
      height: 200px;
      object-fit: cover;
    }

    .title {
      height: 50px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      padding: 0 10px;
      margin-top: 10px;
    }

    .footer-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      height: 30px;
      padding: 10px;

      .time {
      }

      .button {
        width: 30px;
        height: 30px;
        margin-right: 10px;
      }
    }

    .sub-content {
      height: 40px;
      line-height: 20px;
      text-overflow: ellipsis;
      overflow: hidden;
      margin: 0 10px 10px 10px;
    }
  }
}
</style>
